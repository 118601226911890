<template>
  <div class="box box--infoBlock">
    <div class="box__inner">
      <div class="infoBlock infoBlock--document infoBlock--disclaimer">
        <div class="documentInfo">
          <div class="documentInfo__title">Disclaimer</div>
          <div class="documentInfo__content js-customScroll">
            <vue-scroll :ops="ops">
              <article>
                <p>FLY I Corporation, which does business as FLYJETS, acts as an intermediary, helping to connect Flyer
                  customers to air carrier (Aircraft Provider) customers based upon customer requests and needs.</p>
                <p>FLYJETS is NOT an airline, air carrier, operator, or airway. All charter flights will be provided by
                  properly licensed air carriers. FLYJETS does not own or operate, nor does it purport to own operate,
                  any
                  of the aircraft that provide charter and commercial flights to clients.</p>
                <p>The owners and managers of this site do not make any guarantees or warrantees about the flights
                  listed
                  on this site or the persons who operate such flights.</p>
                <p>Use of the services and any reliance by you upon the services, including any action taken by you
                  because of such use or reliance, is at your sole risk. FLYJETS does not warrant that the services will
                  be uninterrupted or error free; nor does it make any warranty as to the results that may be obtained
                  from use of the services. The services are provided “as is” and FLYJETS disclaims all warranties,
                  express or implied, including, but not limited to, implied warranties of merchantability, fitness for
                  a
                  particular purpose, title and noninfringement.</p>
              </article>
            </vue-scroll>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Disclaimer',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    metaInfo: {
      meta: [{
         name: 'robots', content: 'noindex',
      }],
    },
  }
</script>
